<template>
  <div class="login" v-loading="loading">
    <div class="loginwrap">
      <div class="logowrap">
        <i class="logo"></i>
      </div>
      <!-- <div class="line"></div> -->
      <div class="formwrap">
        <el-form
          :model="loginForm"
          status-icon
          :rules="rules"
          ref="loginForm"
          label-width="80px"
          label-position="top"
        >
          <el-form-item label="帳號：" prop="userName">
            <el-input
              placeholder="請輸入帳號"
              v-model="loginForm.userName"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item label="密碼：" prop="passWord">
            <el-input
              placeholder="請輸入密碼"
              v-model="loginForm.passWord"
              show-password
              autocomplete="new-password"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="btnwrap">
          <el-button type="primary" size="medium" @click="handleLogin"
            >登入</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
        title="設置新密碼"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :visible.sync="isShowSetPassword"
        width="30%">
      <el-form
          class="setPassword"
          :model="setPassWordForm"
          status-icon
          :rules="passWordRules"
          ref="setPassWordForm"
          label-width="80px"
          label-position="top"
      >
        <el-form-item label="新密碼：" prop="newPassWord">
          <el-input
              placeholder="請輸入新密碼"
              v-model="setPassWordForm.newPassWord"
              show-password
              autocomplete="new-password"
          ></el-input>
        </el-form-item>
        <el-form-item label="再次輸入新密碼：" prop="newPassWord1">
          <el-input
              placeholder="請輸入再次輸入新密碼"
              v-model="setPassWordForm.newPassWord1"
              show-password
              autocomplete="new-password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: right; margin: 0">
        <!--        <el-button size="mini" type="text" @click="visible = false">取消</el-button>-->
        <el-button type="primary" size="mini" @click="setNewPassword()">提交</el-button>
      </div>
<!--      <span>这是一段信息</span>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
<!--  </span>-->
    </el-dialog>
  </div>
</template>

<script>
import * as api from "./api";
import { setStore } from "@/utils/storage.js";
import { resetRouter } from "@/router";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('請輸入密碼'));
      }else if (value.length<6 || value.length>20 ) {
        callback(new Error('密碼長度應為6-20位數'));
      }
      else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('請再次輸入密碼'));
      }
      else if (value !== this.setPassWordForm.newPassWord) {
        callback(new Error('兩次輸入的密碼不一致!'));
      }
      else {
        callback();
      }
    };
    return {
      isShowSetPassword: false,
      loading:false,
      loginForm: {
        userName: "",
        passWord: "",
      },
      rules: {
        userName: [
          { required: true, message: "請輸入用戶名", trigger: "blur" },
        ],
        passWord: [{ required: true, message: "請輸入密碼", trigger: "blur" }],
      },
      setPassWordForm:{
        newPassWord: "",
        newPassWord1:""
      },
      passWordRules:{
        newPassWord: [{ required: true, message: "請輸入新密碼", trigger: "blur" },
          { validator: validatePass, trigger: 'blur',required: true}],
        newPassWord1: [{ required: true, message: "請再次輸入新密碼", trigger: "blur" },
          { validator: validatePass2, trigger: 'blur',required: true}],
      },
    };
  },
  created() {},
  methods: {
    setNewPassword(){
      this.$refs["setPassWordForm"].validate(valid => {
        if (valid) {
          this.handleLogin()
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleLogin() {
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          this.loading=true;
          let data = {}
          if(this.isShowSetPassword){
            data ={
              newPassWord:this.setPassWordForm.newPassWord,
              account:this.loginForm.userName,
              password:this.loginForm.passWord
            }
          }else{
            data = {
              account:this.loginForm.userName,
              password:this.loginForm.passWord
            }

          }

          api.login(data).then(res=>{
              if(res.systemCode===200){
                setStore("token",res.data.accessToken)
                setStore("userName",res.data.name)
                if(res.data.isHasRestorePasswor){
                  this.loading = false
                  this.isShowSetPassword = true
                }else{
                  this.isShowSetPassword = false
                  this.getAllroless();
                }
              }else{
                this.loading=false;
              }
          }).catch(err=>{
            this.loading=false;
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
     // 獲取当前角色權限
    getAllroless() {
      api.getCurpermissions().then(res=>{
        if(res.systemCode===200){
          setStore("permissions",res.data)
          resetRouter("admin")
          this.$router.push({
            path: "/cms",
          });
          this.loading = false;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #444;
  // background-image: linear-gradient(
  //   rgb(121, 165, 218),
  //   rgb(132, 184, 223),
  //   #fff
  // );
  .loginwrap {
    width: 420px;
    height: 400px;
    margin: 8% auto;
    // border: 1px solid #fff;
    background: #fff;
    border-radius: 0px;
    padding: 20px;
    text-align: left;
    box-sizing: border-box;
    .logowrap {
      width: 100%;
      height: 100px;
      // float: left;
    }
    .logo {
      display: block;
      width: 100px;
      height: 100px;
      background: url("~@/assets/images/logo.jpg") center center no-repeat;
      background-size: cover;
      margin: 0px auto 20px;
    }
    .line {
      width: 1px;
      background: #fff;
      height: 100%;
      float: left;
      margin: 0px 8px;
    }
    .formwrap {
      width: 100%;
      height: 100%;
      // float: right;
      // padding-top: 70px;
    }
    .btnwrap {
      text-align: center;
      padding-top: 15px;
      button {
        // margin-left: 60px;
        width: 100%;
      }
    }
  }
  /deep/ {
    .el-form-item__label {
      color: #444;
    }
    .el-form-item .el-form-item__label:before {
      color: #444;
    }
  }
}
</style>
